import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Layout from "../components/layoutMainCustom"
import BgImage from "../images/Philippines_Big.jpg"
import BaptismPic from "../images/MyanmarBaptism.jpg"
import MeetThePastorPhilippines from "../components/meetthePastorPhilippines"
import SEO from "../components/seo"
import PageHeaders from "../components/pageHeaders"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Philippines Campus"
    SecondText="Making Jesus Known around the world."
  >
    <SEO title="Philippines Campus" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Cross Church Philippines
    </PageHeaders>
    <HeaderText></HeaderText>
    <div
      css={css`
        display: flex;
        @media (max-width: 940px) {
          flex-direction: column;
        }
      `}
    >
      <div>
        <ParagraphText>
          Cross Church Philippines is being launched in Cebu City. There is a
          core group of young professionals that have a heart for reaching their
          community and Making Jesus Known.{" "}
        </ParagraphText>
        <HeaderText></HeaderText>
        <ParagraphText>
          Your{" "}
          <a href="https://pushpay.com/pay/palmvistasurprise/3wVi2Zku2LnNwZiQ57OSwA">
            giving
          </a>{" "}
          through Cross Church helps us to literally take the Gospel around the
          world.
        </ParagraphText>
      </div>
    </div>
    <HeaderText></HeaderText>
    <MeetThePastorPhilippines />
  </Layout>
)

export default IndexPage
